/* .side_bar_section > .btn img {
    filter: invert(1) !important;
}

.search_inner.open {
    height: 140px !important;
}

.btn.bonus {
    background-color: rgb(104 162 0) !important;
}

.footer-wrapper {
    background-color: rgb(104 162 0/40%) !important;
}

.footer-imgs img {
    filter: grayscale(1) drop-shadow(2px 4px 6px black) !important;
}

.jackpot_wrapper {
    background: rgb(40 61 2) !important;
    border-image: linear-gradient(0.25turn, #161816, #adb4b3, #161816) 2 0 2 0 !important;
} */
.collapse h4{
  color: #5b5757;
}
.pages_options{
  .btn.pg{
    color: #7e5f14 ;
    opacity: 1;
  }
  .btn.pg:hover{
    color: #7e5f14 ;
    opacity: 0.6;
  }
  .btn.pg.active{
    color: black;
  }
}
.bonus_slider:before{
  opacity: 1 !important;
}
.search_icon{
  @include recolor(#b5840b)
}
.footer-section span{
  color: #7f6014 ;
}
.footer-section.pages > div .btn{
  color: black;
}
.btn.bonus {
    right: 7vw;
    bottom: auto;
    top: auto;
    height: 70%;
}
.bonus_slider {
    box-shadow: inset 0 -40px 33px 6px #f2e7dc;
}
.header-logo {
 width: 20vw;
    position: relative;
    left: auto;
    height: 90%;
}
.bonus_wrapper{
  justify-content: end;
  background-repeat: no-repeat !important;

  background-size: contain!important;
  background-position: center!important;
  .bonus_container{
    margin-right: 70px;
    img{
      height: 130% !important;
    }
  }
}
//.header-component{
//    height: 100px;
//}
//.bonus_slider {
//    margin-top: 98px;
//}
.search_icons {
  img {

    @include recolor(rgb(40, 40, 40));
  }
}
.promo_right img{
  object-fit: contain;
}
@media screen and (max-width: 950px) {
  .bonus_wrapper{
    background-position-x: 70px !important;
  }

}
@media screen and (max-width: 600px) {
  .bonus_wrapper{
    background: transparent!important;
    justify-content: center;
    .bonus_container{
      margin-right: 0px;
      img{
        margin-bottom: 0 !important;
      }
    }
  }
  .btn.bonus {
    margin-top:40px;
    height: initial;
  }
}